<template>
  <div>
    <v-container grid-list-md>
      <v-layout wrap>
        <v-flex>
          <v-data-table
            :search="search"
            :loading="loading"
            :headers="headers"
            :items="users"
            sort-by="name"
            class="elevation-1 mt-3"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><b>كل المستخدمين</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-flex xs6>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                    on
                  ></v-text-field
                ></v-flex>

                <v-flex xs2>
                  <v-btn
                    small
                    class="text-center"
                    style="color:white;"
                    fab
                    color="green"
                    @click="$router.push({ name: 'add-user' })"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-flex>
              </v-toolbar>
              <v-dialog v-model="dialogDelete" max-width="300px">
                <v-card>
                  <v-card-title class="headline"
                    >هل أنت متأكد من الحذف؟</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text-color="white"
                      text
                      @click="closeDelete"
                      >إلغاء</v-btn
                    >
                    <v-btn
                      color="gray"
                      text-color="white"
                      text
                      @click="deleteItemConfirm"
                      >موافق</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!-- <template v-slot:[`item.user.fullName`]="{ item }"> 
                  {{ item.user.fullName }}
                </template>

                <template v-slot[`:item.user.email`]="{ item }"> 
                  {{ item.user.email }}
                </template>

                <template v-slot:[`item.user.phoneNumber`]="{ item }"> 
                  {{ item.user.phoneNumber }}
                </template> -->

            <template v-slot:[`item.roles`]="{ item }">
              {{ getRole(item.roles[0]) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="green"
                text-color="white"
                small
                class="mr-2"
                @click="
                  $router.push({
                    name: 'show-user',
                    params: { userId: item.user.id }
                  })
                "
              >
                mdi-eye
              </v-icon>
              <v-icon
                color="blue"
                v-if="role == 'Admin'"
                text-color="white"
                small
                class="mr-2"
                @click="
                  $router.push({
                    name: 'edit-user',
                    params: { userId: item.user.id, editMyProfile: false }
                  })
                "
              >
                mdi-pencil
              </v-icon>
              <v-icon
                color="red"
                v-if="role == 'Admin'"
                text-color="white"
                small
                @click="deleteItem(item.user.id)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              لا توجد بيانات
              <v-icon x-small @click="getAllUsers">redo</v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: " الاسم الكامل",
          align: "center",
          sortable: true,
          value: "user.fullName"
        },
        {
          text: "الإيميل",
          align: "center",
          sortable: false,
          value: "user.email"
        },
        {
          text: "الموبايل",
          align: "center",
          sortable: false,
          value: "user.phoneNumber"
        },
        {
          text: "الدور",
          align: "center",
          sortable: false,
          value: "roles"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      dialog: false,
      deleteUser: 0,
      users: [],
      totalItems: 0,
      page: 1,
      role: null
    };
  },
  methods: {
    getRole(role) {
      if (role == "Supervisor") {
        return "مشرف";
      } else if (role == "Salesman") {
        return "مندوب مبيعات";
      } else if (role == "Director") {
        return "مدير إدارة";
      } else if (role == "General Manager Of Dept") {
        return "رئيس قسم";
      } else if (role == "Admin") {
        return "أدمن";
      } else if (role == "CEO") {
        return "مدير عام";
      } else {
        return "-";
      }
    },
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.ApiService.post("auth/DeleteUser?id=" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllUsers();
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    async getAllUsers() {
      this.users = [];
      this.loading = false;
      await this.ApiService.get("auth/listusers")
        .then(res => {
          this.users = res.data;
        })
        .catch(() => {});
      this.loading = false;
    }
  },
  created() {
    this.getAllUsers();
    this.role = window.localStorage.getItem("roles");
  }
};
</script>
<style lang="scss"></style>
